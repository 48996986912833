@tailwind base;
@tailwind components;
/* this file is for public and private/admin pages */
a, .button-link {
  @apply text-blue-500 underline;
  transition: color 0.25s ease;
}

a:hover, a:active, .button-link:hover, .button-link:active {
  @apply text-blue-600;
}

h1, h2, h3, h4, h5, h6 {
  @apply leading-tight font-bold;
}

h1 {
  @apply text-3xl;
  margin: 0.67rem 0;
}

h2 {
  @apply text-2xl;
  margin: 0.83rem 0;
}

h3 {
  @apply text-xl;
  margin: 1rem 0;
}

h4 {
  margin: 1.33rem 0;
}

h5 {
  @apply text-sm;
  margin: 1.67rem 0;
}

h6 {
  @apply text-xs;
  margin: 2.33rem 0;
}

h1 a, h2 a, h3 a, h4 a {
  @apply no-underline text-inherit;
}

h1 a:hover, h1 a:active, h2 a:hover, h2 a:active, h3 a:hover, h3 a:active, h4 a:hover, h4 a:active {
  @apply underline text-inherit;
}

p {
  margin: 1em 0;
}

ul, ol {
  @apply pl-10;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

address {
  @apply not-italic;
}

td {
  @apply align-top;
}

.transition {
  transition: all 0.25s ease;
}

html {
  @apply font-body leading-relaxed text-gray-800;
}

a, .button-link {
  @apply text-teal-600 no-underline font-light;
}

a:hover, a:active, .button-link:hover, .button-link:active {
  @apply underline text-teal-700;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-display leading-tight;
}

h2, h3 {
  @apply -ml-2;
}

h1 {
  @apply text-5xl;
}

@screen lg {
  h1 {
    font-size: 5rem;
  }
}

h2 {
  @apply text-5xl mt-8 mb-2 leading-none;
}

@screen lg {
  h2 {
    @apply text-6xl;
  }
}

h3 {
  @apply text-3xl mt-8 mb-6;
}

@screen lg {
  h3 {
    @apply text-5xl;
  }
}

h4 {
  @apply text-2xl mt-8 mb-2;
}

@screen lg {
  h4 {
    @apply text-3xl;
  }
}

p:first-of-type, h3 + p, h4 + p {
  @apply mt-0;
}

.list-disc li {
  @apply mb-3;
}

.list-disc li:last-of-type {
  @apply mb-0;
}

.w-content {
  @apply max-w-11/12 mx-auto;
}

@screen xl {
  .w-content {
    @apply max-w-6xl;
  }
}

.w-content-narrow {
  @apply max-w-11/12 mx-auto;
}

@screen md {
  .w-content-narrow {
    @apply max-w-lg;
  }
}

@screen xl {
  .w-content-narrow {
    @apply max-w-4xl;
  }
}

.w-content-copy {
  @apply max-w-11/12 mx-auto;
}

@screen md {
  .w-content-copy {
    @apply max-w-md;
  }
}

@screen xl {
  .w-content-copy {
    @apply max-w-xl;
  }
}

.bg-slate {
  @apply bg-no-repeat bg-gray-800 text-white;
  background-image: url("/images/slate.jpg");
}

.alert {
  @apply flex justify-between w-4/5 mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10;
}

.form-wrap .alert {
  @apply w-full;
}

.alert-success {
  @apply border-green-500;
}

.alert-info {
  @apply border-blue-400;
}

.alert-warning {
  @apply border-orange-500;
}

.alert-danger {
  @apply border-red-500;
}

.content-img-center {
  @apply block mx-auto my-4;
}

.content-img-left {
  @apply float-left my-4 mr-4;
}

.content-img-right {
  @apply float-right my-4 ml-4;
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  @apply w-full px-2 py-1 text-black border border-gray-400 rounded-sm bg-gray-200;
  transition: background-color 0.25s ease;
}

[type=text]:focus,
[type=password]:focus,
[type=date]:focus,
[type=datetime]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=week]:focus,
[type=email]:focus,
[type=number]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=url]:focus,
[type=color]:focus,
textarea:focus {
  @apply border-gray-500 bg-gray-100;
}

textarea {
  @apply align-top text-black;
}

select {
  @apply w-full p-1 text-black border border-gray-400 rounded-sm bg-gray-200;
  height: 2.14rem;
  transition: background-color 0.25s ease;
}

select:focus {
  @apply border-gray-500 bg-gray-100;
}

[type=checkbox] {
  @apply mr-2;
}

[type=radio] {
  @apply relative mr-1;
  top: -1px;
}

[type=submit], [type=image], label, select {
  @apply cursor-pointer;
}

[type=file] {
  width: 100%;
}

.form-wrap {
  @apply w-11/12 max-w-lg mx-auto my-4 p-4 rounded-sm shadow-md;
}

.field-wrap {
  @apply mb-4;
}

.field-wrap label {
  @apply block mb-1;
}

.field-wrap-checkbox {
  @apply flex flex-wrap items-center mb-4;
}

.field-wrap-checkbox label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radio {
  @apply mb-1;
}

.field-wrap-radios, .field-wrap-checkboxes {
  @apply mb-4;
}

.field-wrap-radios label, .field-wrap-checkboxes label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radios legend, .field-wrap-checkboxes legend {
  @apply mb-1;
}

.field-wrap-radios .field-wrap-checkbox, .field-wrap-checkboxes .field-wrap-checkbox {
  @apply mb-1;
}

.field-errors {
  @apply mb-1 pl-0 list-none;
}

.field-errors li {
  @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
}

.field-wrap-readonly {
  @apply relative;
}

.field-wrap-readonly input {
  @apply opacity-50;
}

.field-wrap-readonly:after {
  @apply absolute right-0 text-xs opacity-50;
  top: 0.5rem;
  content: "read-only";
}

.field-help {
  @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
  padding-left: 0.1em;
}

.form-action {
  @apply text-sm ml-4;
}

.md-enter-active,
.md-leave-active {
  transition: all 0.5s;
}

.md-enter,
.md-leave-active {
  opacity: 0;
  transform: translateY(24px);
}

.banner {
  @apply bg-no-repeat bg-center bg-cover h-64;
}

@screen lg {
  .banner {
    height: 24rem;
  }
}

.banner-about {
  background-image: url("/images/banner-about-01.jpg");
}

.banner-weddings {
  background-image: url("/images/weddings-003.jpg");
}

.banner-heading-hp {
  @apply text-5xl text-center;
}

@screen md {
  .banner-heading-hp {
    @apply text-left;
  }
}

@screen lg {
  .banner-heading-hp {
    font-size: 6rem;
  }
}

.banner-content {
  @apply pt-8;
}

@screen md {
  .banner-content {
    @apply pt-4;
    width: 32rem;
  }
}

button, .button {
  @apply font-display bg-yellow-300 py-2 pr-8 text-gray-700 text-3xl font-bold border border-gray-700 rounded;
  padding-left: 1.65rem;
  transition: all 0.25s ease;
}

button:hover, .button:hover {
  @apply bg-yellow-400 text-gray-800 border-gray-800;
}

a.button {
  @apply inline-block no-underline text-gray-700;
}

.header {
  @apply bg-gray-800 bg-cover;
  background-image: url("/images/slate.jpg");
}

.header-nav_item {
  @apply hidden text-base;
}

@screen lg {
  .header-nav_item {
    @apply inline-block mr-6 text-xl;
  }
  .header-nav_item:last-of-type {
    @apply mr-0;
  }
}

.header-nav_item-home {
  @apply inline-block mr-4 mb-4;
}

@screen lg {
  .header-nav_item-home {
    @apply block mr-0;
  }
}

.header-nav_item-logo {
  @apply inline-block;
}

.header-nav_item-menu {
  @apply absolute block;
  right: 2rem;
  top: 2rem;
}

@screen lg {
  .header-nav_item-menu {
    @apply hidden;
  }
}

.header-nav_item a {
  @apply text-white;
}

.header-nav_item a:hover {
  @apply no-underline text-yellow-300;
}

.header_mobile-open ul {
  @apply flex flex-col mt-6 text-center text-lg;
}

.header_mobile-open .header-nav_item {
  @apply block py-2 order-2;
  transition: all 0.25s ease-in-out;
}

.header_mobile-open .header-nav_item:last-of-type {
  @apply border-0;
}

.header_mobile-open .header-nav_item a {
  @apply block w-full;
}

.header_mobile-open .header-nav_item-logo {
  @apply order-1 mb-4;
}

.bg-faq {
  @apply py-12 bg-repeat;
  background-size: 100%;
}

.faq-wrap {
  @apply mx-auto pb-8 px-4 bg-black-transparent;
  max-width: 45rem;
}

.faq-item {
  @apply max-w-2xl mx-auto py-8 border-b border-white rounded text-white;
}

.faq-item:last-of-type {
  @apply border-b-0 pb-0;
}

.faq-question {
  @apply text-3xl w-4/5 my-0 font-normal;
}

@screen xl {
  .faq-question {
    @apply w-11/12;
  }
}

.faq-content {
  @apply hidden max-w-2xl mx-auto w-4/5 mt-4 pl-8;
}

@screen xl {
  .faq-content {
    @apply w-11/12;
  }
}

.faq-content p:last-of-type {
  @apply mb-0;
}

.faq_list-hide {
  @apply hidden;
}

.faq-open .faq-content {
  @apply block;
}

.faq-open .faq_list-view {
  @apply hidden;
}

.faq-open .faq_list-hide {
  @apply block;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-auto-rows: minmax(22rem, auto);
  @apply justify-center max-w-sm mx-auto mb-16 px-4;
}

@screen md {
  .grid {
    @apply max-w-md;
  }
}

@screen lg {
  .grid {
    @apply max-w-4xl w-4/5;
  }
}

@screen max {
  .grid {
    @apply justify-between max-w-6xl w-full;
  }
}

.menu-wrap {
  @apply relative w-full mx-auto p-4 border border-gray-800 rounded shadow;
  transition: all 0.25s ease;
}

.menu-wrap:hover {
  @apply no-underline shadow-lg;
}

.bg-quotation {
  text-align: center;
  background-image: url("/images/quotation.svg");
  background-size: 8rem;
  background-position: 1.5em 2em;
  @apply max-w-2xl p-8 mx-auto my-4 bg-no-repeat;
}

@screen lg {
  .bg-quotation {
    @apply w-1/2 mx-0;
  }
}

#silentbox-group {
  text-align: center;
}

#silentbox-overlay {
  z-index: 9999 !important;
}

.silentbox-item {
  display: inline-block;
  overflow: hidden;
}

.silentbox-item img {
  transition: all 0.5s ease;
}

.silentbox-item:hover {
  overflow: hidden;
}

.silentbox-item:hover img {
  transform: scale(1.05);
}

.bio-wrap {
  @apply w-4/5 mx-auto;
}

@screen md {
  .bio-wrap {
    width: 25rem;
  }
}

.bio-wrap:first-of-type {
  @apply mr-24;
}

.event-copy {
  @apply pb-8;
}

.event-copy li {
  @apply mb-2;
}

.wedding-img-wrap {
  @apply justify-center mt-6 mb-8;
}

@screen md {
  .wedding-img-wrap {
    @apply flex;
  }
}

.wedding-img {
  @apply h-48 px-4 bg-cover bg-center;
}

@screen md {
  .wedding-img {
    @apply w-1/3 h-64;
  }
}

.wedding-img-01 {
  background-image: url("/images/weddings-001.jpg");
}

.wedding-img-02 {
  background-image: url("/images/weddings-002.jpg");
}

.wedding-img-03 {
  background-image: url("/images/weddings-003.jpg");
}

@tailwind utilities;
