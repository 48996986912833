.banner {
    @apply bg-no-repeat bg-center bg-cover h-64;
    @screen lg {
        height: 24rem;
    }
    &-about {
        background-image: url('/images/banner-about-01.jpg');
    }
    &-weddings {
        background-image: url('/images/weddings-003.jpg');
    }
}

.banner-heading-hp {
    @apply text-5xl text-center;
    @screen md {
        @apply text-left;
    }
    @screen lg {
        font-size: 6rem;
    }
}

.banner-content {
    @apply pt-8;
    @screen md {
        @apply pt-4;
        width: 32rem;
    }
}
