.bg-quotation {
    text-align: center;
    background-image: url('/images/quotation.svg');
    background-size: 8rem;
    background-position: 1.5em 2em;
    @apply max-w-2xl p-8 mx-auto my-4 bg-no-repeat;

    @screen lg {
        @apply w-1/2 mx-0;
    }
}
