.header {
    @apply bg-gray-800 bg-cover;
    background-image: url('/images/slate.jpg');
}

.header-nav_item {
    @apply hidden text-base;
    @screen lg {
        @apply inline-block mr-6 text-xl;
        &:last-of-type {
            @apply mr-0;
        }
    }
    &-home {
        @apply inline-block mr-4 mb-4;
        @screen lg {
            @apply block mr-0;
        }
    }
    &-logo {
        @apply inline-block;
    }
    &-menu {
        @apply absolute block;
        right: 2rem;
        top: 2rem;
        @screen lg {
            @apply hidden;
        }
    }

    a {
        @apply text-white;
        &:hover {
            @apply no-underline text-yellow-300;
        }
    }
}

.header_mobile-open {
    ul {
        @apply flex flex-col mt-6 text-center text-lg;
    }
    .header-nav_item {
        @apply block py-2 order-2;
        transition: all 0.25s ease-in-out;
        &:last-of-type {
            @apply border-0;
        }
        a {
            @apply block w-full;
        }
    }
    .header-nav_item-logo {
        @apply order-1 mb-4;
    }
}
