.wedding-img-wrap {
    @apply justify-center mt-6 mb-8;
    @screen md {
        @apply flex;
    }
}

.wedding-img {
    @apply h-48 px-4 bg-cover bg-center;
    @screen md {
        @apply w-1/3 h-64;
    }
    &-01 {
        background-image: url('/images/weddings-001.jpg');
    }
    &-02 {
        background-image: url('/images/weddings-002.jpg');
    }
    &-03 {
        background-image: url('/images/weddings-003.jpg');
    }
}
