.grid {
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-auto-rows: minmax(22rem, auto);
    @apply justify-center max-w-sm mx-auto mb-16 px-4;
    @screen md {
        @apply max-w-md;
    }
    @screen lg {
        @apply max-w-4xl w-4/5;
    }
    @screen max {
        @apply justify-between max-w-6xl w-full;
    }
}


.menu-wrap {
    @apply relative w-full mx-auto p-4 border border-gray-800 rounded shadow;
    transition: all 0.25s ease;
    &:hover {
        @apply no-underline shadow-lg;
    }
}
