#silentbox-group {
    text-align: center;
}

#silentbox-overlay {
    z-index: 9999 !important;
}

.silentbox-item {
    display: inline-block;
    overflow: hidden;
    img { transition: all 0.5s ease; }
    &:hover {
        overflow: hidden;
        img {
            transform: scale(1.05);
        }
    }
}
