button, .button {
    @apply font-display bg-yellow-300 py-2 pr-8 text-gray-700 text-3xl font-bold border border-gray-700 rounded;
    padding-left: 1.65rem;
    transition: all 0.25s ease;
    &:hover {
        @apply bg-yellow-400 text-gray-800 border-gray-800;
    }
}

a.button {
    @apply inline-block no-underline text-gray-700;
}

