.bg-faq {
    @apply py-12 bg-repeat;
    background-size: 100%;
}

.faq-wrap {
    @apply mx-auto pb-8 px-4 bg-black-transparent;
    max-width: 45rem;
}

.faq-item {
    @apply max-w-2xl mx-auto py-8 border-b border-white rounded text-white;
    &:last-of-type {
        @apply border-b-0 pb-0;
    }
}

.faq-question {
    @apply text-3xl w-4/5 my-0 font-normal;
    @screen xl {
        @apply w-11/12;
    }
}

.faq-content {
    @apply hidden max-w-2xl mx-auto w-4/5 mt-4 pl-8;
    @screen xl {
        @apply w-11/12;
    }
    p:last-of-type {
        @apply mb-0;
    }
}

.faq_list-hide {
    @apply hidden;
}

.faq-open {
    .faq-content {
        @apply block;
    }
    .faq_list-view {
        @apply hidden;
    }
    .faq_list-hide {
        @apply block;
    }
}
