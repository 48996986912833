@tailwind base;

@tailwind components;

@import "common/common";

// global styles on the most common elements
html {
    @apply font-body leading-relaxed text-gray-800;
}

a, .button-link {
    @apply text-teal-600 no-underline font-light;
    &:hover, &:active {
        @apply underline text-teal-700;
    }
}

h1, h2, h3, h4, h5, h6 {
    @apply font-display leading-tight;
}

h2, h3 {
    @apply -ml-2;
}

h1 {
    @apply text-5xl;
    @screen lg {
        font-size: 5rem;
    }
}

h2 {
    @apply text-5xl mt-8 mb-2 leading-none;
    @screen lg {
        @apply text-6xl;
    }
}

h3 {
    @apply text-3xl mt-8 mb-6;
    @screen lg {
        @apply text-5xl;
    }
}

h4 {
    @apply text-2xl mt-8 mb-2;
    @screen lg {
        @apply text-3xl;
    }
}

//reduce margin between headings and paragraphs
p:first-of-type, h3 + p, h4 + p {
    @apply mt-0;
}

.list-disc li {
    @apply mb-3;
    &:last-of-type {
        @apply mb-0;
    }
}

.w-content {
    @apply max-w-11/12 mx-auto;
    @screen xl {
        @apply max-w-6xl;
    }
}

.w-content-narrow {
    @apply max-w-11/12 mx-auto;
    @screen md {
        @apply max-w-lg;
    }
    @screen xl {
        @apply max-w-4xl;
    }
}

.w-content-copy {
    @apply max-w-11/12 mx-auto;
    @screen md {
        @apply max-w-md;
    }
    @screen xl {
        @apply max-w-xl;
    }
}

.bg-slate {
    @apply bg-no-repeat bg-gray-800 text-white;
    background-image: url('/images/slate.jpg');
}

// common styles
@import "common/alerts",
    "common/content_alignment",
    "common/forms",
    "common/modal";

// public only styles
@import "public/banner",
    "public/buttons",
    "public/header",
    "public/faq",
    "public/menus",
    "public/testimonials";

// page specific
@import "public/gallery",
    "public/p_about",
    "public/p_events",
    "public/p_weddings";

@tailwind utilities;
